import React from 'react';
import axios from 'axios';

import InnerHTML from 'dangerously-set-html-content';

export default class WorkingPapers extends React.Component {
  state = {
    content: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>'
  }

  componentDidMount() {
    document.querySelector('#header-wrap-react').classList.add('internal');
    axios.get(process.env.REACT_APP_PROXY_PATH+`/proxy.php?slug=youth%2Fworking-papers`)
      .then(res => {
        const content = res.data;
        this.setState({ content });
      })
  }

  render() {
    return (
      <InnerHTML className="youth-summer-jobs" html={this.state.content} />
    )
  }
}