import React from 'react';
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from './../images/logo.svg';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// Detects if device is on iOS 
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

export default class Home extends React.Component {
  componentDidMount() {
    document.querySelector('#header-wrap-react').classList.remove('internal');
  }
  render() {
    return (
      <div className="home">
        <div className="wrap-content">
          <nav id="react-nav">
            <ul>
              <li><Link className="icon-hands" to="/youth/">Youth Programs</Link></li>
              <li><Link className="icon-case" to="/youth/summer-jobs/">2022 Summer <br />Jobs Program</Link></li>
              <li><Link className="icon-bulb" to="/youth/skills-training/">Youth Skills <br />Training</Link></li>
              <li><Link className="icon-paper" to="/youth/working-papers/">Working Papers</Link></li>
              <li><Link className="icon-wave" to="/welcome-center/">Welcome <br />Center</Link></li>
              <li><a className="icon-text" href="sms:8332648478?&body=MYFUTURE">Text Us!</a></li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}