import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { Link, NavLink, Outlet } from "react-router-dom";

import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as AJC } from './images/ajc.svg';
import { ReactComponent as Down } from './images/down.svg';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import Home from './pages/home';
import Youth from './pages/youth';
import SummerJobs from './pages/youth-summer-jobs';
import SkillsTraining from './pages/youth-skills-training';
import WorkingPapers from './pages/youth-working-papers';
import WelcomeCenter from './pages/welcome-center';

import './style.css';

// import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// const client = new ApolloClient({
//   uri: "https://paipfsupportnetwork.org/paipfreact/",
//   cache: new InMemoryCache()
// });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <header id="header-wrap-react" className="wrap-react">
        <div className="wrap-content-react">
          <Link to="/" className="app-home" title="App Home" aria-label="App Home"></Link>
          <Link to="/" className="logo" title="Career Link Lehigh Valley" aria-label="Career Link Lehigh Valley">
            <Logo />
          </Link>
        </div>
      </header>
      <main id="main-wrap-react" className="wrap-react">
        <Routes>          
          <Route path="/" element={<Home />} />
          <Route path="youth" element={<Youth />} />
          <Route path="youth/summer-jobs" element={<SummerJobs />} />
          <Route path="youth/skills-training" element={<SkillsTraining />} />
          <Route path="youth/working-papers" element={<WorkingPapers />} />
          <Route path="welcome-center" element={<WelcomeCenter />} />
        </Routes>   
      </main>
      <footer id="footer-wrap-react" className="wrap-react">
        <div className="wrap-content-react">
          <AJC className="ajc-logo" />
          <input id="footer-check" type="checkbox" className="hidden-check" />
          <label for="footer-check"><Down /></label>
          <div class="footer-text">
            <p>Auxiliary aids and services are available upon request to individuals with disabilities.</p>
            <p>Equal Opportunity Employer/Program</p>
            <p>Programs at the PA CareerLink&reg; are funded whole or in part by federal funds.</p>
            <p>Detailed information can be found at https://lvwib.org/stevens-amendment/</p>
            <p>Copyright &copy; 2022 PA CareerLink&reg; Lehigh Valley. All Rights Reserved.</p>
          </div>
        </div>
      </footer>    
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
